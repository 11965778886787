import React from "react";
import { useTheme, IconCommon, IconName, Size } from "@technis/ui";
import classNames from "classnames";

type IconButtonProps = {
  onClick?: () => void;
  iconName: IconName;
  label: string;
};

export const IconButton = ({ onClick, iconName, label }: IconButtonProps) => {
  const { theme } = useTheme();

  return (
    <div className={classNames("icon-button", onClick && "icon-button-cursor")} onClick={onClick}>
      <IconCommon name={iconName} color={theme.colors.BASE_40} size={Size.EXTRA_LARGE_2} />
      <span style={{ color: theme.colors.BASE_40 }} className="icon-button-label">
        {label}
      </span>
    </div>
  );
};
